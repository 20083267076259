/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import beeBG from '../assets/hero_bg.jpg';
import logo from '../assets/logo_lgr.png';
import MainMenu from '../components/MainMenu';
import useMediaQuery from '@mui/material/useMediaQuery';
import TokenSupply from '../components/TokenSupply';
// import AddTokenButton from '../components/AddTokenButton';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import discord from "../assets/discord.png"
// import twitter from "../assets/twitter.png"
// import telegram from "../assets/telegram.png"
// import wave from "../assets/image.png"
// import header from "../assets/top_nav_s4.png"

const HomePage = () => {
  const [connectedAcc, setConnectedAcc] = useState(undefined);

  const mobile = useMediaQuery('(max-width: 767px)');

  const [balance, setBalance] = useState(0);

  function getAccBalance(acc){
    fetch(`https://presalebackend.cryptopoker.tech/accountInfo/${acc}`)
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setBalance(data.total_tokens)
      })
      .catch(error => {
          console.log("Error fetching total tokens used:", error);
      });
  }

  const connectMetask = async () => {
    await window.ethereum.enable();

    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    let account = accounts[0];
    let truncatedAcc =
      account.slice(0, 10) + '......' + account.slice(account.length - 4);
    console.log(account, 'acc');
    setConnectedAcc(account);
    getAccBalance(account);
  };

  const clientArea = async () => {
    window.location.href = "https://boorio.tech/login.html";
  }

  const navStyle = {
    color: "white",
    textDecoration: "none",
    fontSize: "18px",
    fontWeight: "600",
    fontSize: 15,
  }

  const activeNavStyle = {
    color: "#4100ff",
    textDecoration: "none",
    fontSize: "18px",
    fontWeight: "600",
    fontSize: 15,
  }

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="dark"
        />
      <Box sx={{ background: "#0b0a1f", position: "relative", zIndex: 5, padding: "20px 25px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box>
          <Box sx={{ 
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: 'space-between',
            alignItems: "center",
            gap: '4.5rem'
          }}>
            <img onClick={() => window.open("https://boorio.tech", "_blank")} src={logo} style={{ height: "50px" }} />
            <Box style={{ display: mobile ? "none" : "flex", alignItems: 'center', justifyContent: 'center', gap: '1.75rem' }}>
              <a href="https://boorio.tech/" style={navStyle}>Home</a>
              <a href="https://explorer.boorio.tech/" style={activeNavStyle}>Blockchain Explorer</a>
              <a href="https://boorio.tech/project-details.html" style={navStyle}>Boorio Poker</a>
              <a href="https://presale.boorio.tech/" style={navStyle}>Presale</a>
              <a href="https://boorio.tech/contact.html" style={navStyle}>Contact</a>
            </Box>
            <Box
              sx={{
                border: "2px solid",
                padding: '10px 35px',
                zIndex: '2',
                cursor: 'pointer',
                borderRadius: "100px",
                color: "white",
                fontWeight: '600',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0.5rem',
              }}
              onClick={connectMetask}
            >
              <img src="lock.png" alt="lock icon" width={'18px'} sx={{ width: '1px', }} />
              {connectedAcc ? `Connected to ${connectedAcc.slice(0, 4)}...${connectedAcc.slice(-4)}` : "Connect Wallet"}
            </Box>
            <Box sx={{
              color: "white",
              position: "absolute",
              right: "18.8vw",
              top: 80,
              padding: '8px 20px',
              zIndex: '2',
              borderRadius: "100px",
              background: "#282a4e",
            }}>
              {connectedAcc ? `Balance: ${balance ? balance : 0} BOORIO` : "Connect your account to see your balance"}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',

          backgroundColor: '#090919',
          width: '100%',
          minHeight: '90vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: '1',
            objectFit: 'cover',
            opacity: "0.1",
            filter: "blur(1px)"
          }}
          src={beeBG}
          alt=""
        />
        <Box sx={{ zIndex: 2 }}>
          <TokenSupply />
          <MainMenu connectedAcc={connectedAcc} />
          {/* <AddTokenButton /> */}
        </Box>
      </Box>

      {/* <h1>Join our Community</h1>

        <center>
      <Box style={{ display: "flex", justifyContent: "space-around", width: "80%", padding: "20px", border: "1px solid #ebebeb", borderRadius: "10px" }}>
        <Box onClick={() => window.open("https://discord.gg/Yg7z8gCUW5")} sx={{ display: "flex", gap: "1rem", alignItems: "center", cursor: "pointer" }}>
          <img src={discord} width={70} style={{ objectFit: "contain" }} />
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <Typography sx={{ fontWeight: 600, fontSize: "1.5rem" }}>Discord</Typography>
            <Typography sx={{ fontSize: "0.8rem" }}>For technical questions</Typography>
          </Box>
        </Box>

        <Box sx={{ width: "1px", height: "100px", background: "#ebebeb" }} />

        <Box onClick={() => window.open("https://twitter.com/AIDecentral")} sx={{ display: "flex", gap: "1rem", alignItems: "center", cursor: "pointer" }}>
          <img src={twitter} width={70} style={{ objectFit: "contain" }} />
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <Typography sx={{ fontWeight: 600, fontSize: "1.5rem" }}>Twitter</Typography>
            <Typography sx={{ fontSize: "0.8rem" }}>Latest news and update</Typography>
          </Box>
        </Box>

        <Box sx={{ width: "1px", height: "100px", background: "#ebebeb" }} />

        <Box onClick={() => window.open("https://t.me/+C_WGH0ri19diMWZk")} sx={{ display: "flex", gap: "1rem", alignItems: "center", cursor: "pointer" }}>
          <img src={telegram} width={70} style={{ objectFit: "contain" }} />
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <Typography sx={{ fontWeight: 600, fontSize: "1.5rem" }}>Telegram</Typography>
            <Typography sx={{ fontSize: "0.8rem" }}>For general trading info</Typography>
          </Box>
        </Box>
      </Box>
      </center> */}

      <Box sx={{ paddingTop: "100px", display: "flex", flexDirection: "column", background: "#09091d" }}>
        {/* <img src={wave} alt="wave" /> */}
        <Box sx={{ background: "#09091d", padding: "0 100px 2rem 100px", width: "100%", display: "flex", flexDirection: "column", gap: "2rem"}}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
            <img style={{ height: "50px" }} src={logo} alt="logo" />
            <Box sx={{ display: "flex", gap: "2rem" }}>
              <Typography component="a" href='#' sx={{ color: "darkgray", textDecoration: "none" }}>Client Area</Typography>
              <Typography component="a" href='https://boorio.tech/contact.html' sx={{ color: "darkgray", textDecoration: "none" }}>Get in touch</Typography>
              <Typography component="a" href='https://boorio.tech/project-details.html' sx={{ color: "darkgray", textDecoration: "none" }}>Check out poker</Typography>
              <Typography component="a" href='https://boorio.tech/privacy.html' sx={{ color: "darkgray", textDecoration: "none" }}>Privacy policy</Typography>
            </Box>
          </Box>

          <Box sx={{ width: "100%", height: "1px", background: "darkgray", opacity: "0.5" }}/>

          <Typography sx={{ color: "darkgray" }}>©2024 boorio, Powered by Smithnet LTD</Typography>
        </Box>
      </Box>
    </>
  );
};
export default HomePage;
