import { useState } from 'react';
import { Box } from '@mui/material';
import CardBuyToken from './CardBuyToken';
import CardMyAccount from './CardMyAccount';
import CardStageInfo from './CardStageInfo';

const MainMenu = ({ connectedAcc }) => {
  const [currentSelected, setCurrentSelected] = useState(0);
  return (
    <Box
      sx={{
        backgroundColor: '#16172c',
        // border: "2px solid #929192",
        maxWidth: '550px',
        height: 'auto',
        zIndex: '2',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '25px 0px',
        borderRadius: '10px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          width: '90%',
          marginBottom: '0.5rem',
          fontSize: '16px',
        }}
      >
        {/* <Box
          sx={{
            background:
              currentSelected == 0
                ? 'linear-gradient(90deg, #8645D2 0.07%, #5834A4 99.9%)'
                : '#fff',
            padding: '8px',
            borderRadius: '5px',
            color: currentSelected == 0
            ? 'white'
            : '#605f60',
            cursor: 'pointer',
            marginRight: '0.5rem',
            border: currentSelected == 0
            ? 'none'
            : '1px solid #d5d4d5',
          }}
          onClick={() => setCurrentSelected(0)}
        >
          Buy Tokens
        </Box>
        <Box
          sx={{
            background:
              currentSelected == 1
                ? 'linear-gradient(90deg, #8645D2 0.07%, #5834A4 99.9%)'
                : '#fff',
            padding: '8px',
            borderRadius: '5px',
            color: currentSelected == 1
            ? 'white'
            : '#605f60',
            cursor: 'pointer',
            marginRight: '0.5rem',
            border: currentSelected == 1
            ? 'none'
            : '1px solid #d5d4d5',
          }}
          onClick={() => setCurrentSelected(1)}
        >
          My Account
        </Box>
        <Box
          sx={{
            background:
              currentSelected == 2
                ? 'linear-gradient(90deg, #8645D2 0.07%, #5834A4 99.9%)'
                : '#fff',
            padding: '8px',
            borderRadius: '5px',
            color: currentSelected == 2
            ? 'white'
            : '#605f60',
            cursor: 'pointer',
            marginRight: '0.5rem',
            border: currentSelected == 2
            ? 'none'
            : '1px solid #d5d4d5',
          }}
          onClick={() => setCurrentSelected(2)}
        >
          Stage Info
        </Box> */}
      </Box>
      {currentSelected == 0 ? (
        <CardBuyToken connectedAcc={connectedAcc} />
      ) : currentSelected == 1 ? (
        <CardMyAccount connectedAcc={connectedAcc} />
      ) : (
        <CardStageInfo />
      )}
    </Box>
  );
};
export default MainMenu;
