import { Box, Button, Typography } from '@mui/material';

const TOKEN_PRICE = 1

const CardStageInfo = () => {
  return (
    <Box
      sx={{
        width: '91%',
        height: '90%',
        backgroundColor: '#f1f1f1',
        borderRadius: '10px',
        fontSize: '1rem',
        color: 'white',
        padding: '20px',
        display: 'flex',
        // justifyContent: 'flex-start',
        // alignItems: 'center',
        flexDirection: 'column',
        "*": {
          color: "black",
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ fontSize: '1rem' }}>Buy Tokens</Typography>
        <Box
          sx={{
            border: "1px solid #d5d4d5",
            borderRadius: '10px',
            padding: '5px',
            margin: '0.2rem 0rem',
            color: "#605f60"
          }}
        >
          STAGE 1 PRICE ${TOKEN_PRICE} Per Token
        </Box>
      </Box>
      <br />
      <hr
        style={{
          width: '100%',
          height: '0.1px',
          border: 'none',
          backgroundColor: '#6d6e70',
        }}
      />
      <br />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Typography sx={{ fontSize: '0.7rem', color: "#605f60" }}>
            Current Price
          </Typography>
          <Box
          sx={{
            borderRadius: '10px',
            padding: '5px 10px',
            border: '0.5px solid #605f60',
            width: '100%',
            textAlign: 'left',
            margin: '0.2rem 0rem',
          }}
        >
            ${TOKEN_PRICE} Per Token
          </Box>
        </Box>
      </Box>

      <br />
      <hr
        style={{
          width: '100%',
          height: '0.1px',
          border: 'none',
          backgroundColor: '#6d6e70',
        }}
      />
      <br />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Typography sx={{ fontSize: '0.7rem' }}>
          Current Stage ends on
        </Typography>
        <Box
          sx={{
            borderRadius: '10px',
            padding: '5px 10px',
            border: '0.5px solid #605f60',
            width: '100%',
            textAlign: 'left',
            margin: '0.2rem 0rem',
          }}
        >
          September 1, 2023
        </Box>
      </Box>

      <Box sx={{ textAlign: 'left', margin: '1rem 0rem', fontSize: '14px', opacity: 0.7 }}>
        Please Note:
        <br /> Purchases are final and tokens will be sent to the wallet address
        you have used to pay at the end of the ICO. There is no refund available
        for any purchase.
      </Box>
      <br />
    </Box>
  );
};

export default CardStageInfo;
