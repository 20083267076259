import { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const TOKEN_PRICE = 1

const CardMyAccount = ({ connectedAcc }) => {
  const [convertedData, setConvertedData] = useState('');
  const [totalTWZ, setTotalTWZ] = useState('');

  const getTotalTokens = async () => {
    fetch(`https://backend.beeswap.io/accountInfo/${connectedAcc}`)
    .then(response => response.json())
    .then(data => {
      console.log(data.total_tokens ? parseFloat(data.total_tokens) : 0.0001);
      setTotalTWZ(data.total_tokens ? parseFloat(data.total_tokens) : 0.0001)
    })

    // setTotalTWZ(toFixed(totalTWZ, 5));
  };

  useEffect(() => {
    getTotalTokens();
  }, [convertedData]);

  return (
    <Box
      sx={{
        width: '91%',
        height: '90%',
        backgroundColor: '#f1f1f1',
        borderRadius: '10px',
        fontSize: '1rem',
        color: 'white',
        padding: '20px',
        display: 'flex',
        // justifyContent: 'flex-start',
        // alignItems: 'center',
        flexDirection: 'column',
        "*": {
          color: "black",
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ fontSize: '1rem' }}>Buy Tokens</Typography>
        <Box
          sx={{
            border: "1px solid #d5d4d5",
            borderRadius: '10px',
            padding: '5px',
            margin: '0.2rem 0rem',
            color: "#605f60"
          }}
        >
          STAGE 1 PRICE ${TOKEN_PRICE} Per Token
        </Box>
      </Box>
      <br />
      <hr
        style={{
          width: '100%',
          height: '0.1px',
          border: 'none',
          backgroundColor: '#6d6e70',
        }}
      />
      <br />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Typography sx={{ fontSize: '0.7rem', color: "#605f60" }}>
            Your Connected Wallet ID
          </Typography>
          <Box
          sx={{
            borderRadius: '10px',
            padding: '5px 10px',
            border: '0.5px solid #605f60',
            width: '100%',
            textAlign: 'left',
            margin: '0.2rem 0rem',
          }}
        >
            {connectedAcc}
          </Box>
        </Box>
      </Box>

      <br />
      <hr
        style={{
          width: '100%',
          height: '0.1px',
          border: 'none',
          backgroundColor: '#6d6e70',
        }}
      />
      <br />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Typography sx={{ fontSize: '0.7rem', color: "#605f60" }}>
          Current token balance
        </Typography>
        <Box
          sx={{
            borderRadius: '10px',
            padding: '5px 10px',
            border: '0.5px solid #605f60',
            width: '100%',
            textAlign: 'left',
            margin: '0.2rem 0rem',
          }}
        >
          {totalTWZ ? <span>{parseFloat(totalTWZ).toFixed(3)} AITECH &nbsp;│&nbsp; $ {parseFloat(totalTWZ * TOKEN_PRICE).toFixed(3)}</span> : <CircularProgress color='inherit' />}
        </Box>
      </Box>

      <Box sx={{ textAlign: 'left', margin: '1rem 0rem', fontSize: '14px', opacity: 0.7 }}>
        Please Note:
        <br /> Purchases are final and tokens will be sent to the wallet address
        you have used to pay at the end of the ICO. There is no refund available
        for any purchase.
      </Box>
      <br />
    </Box>
  );
};

export default CardMyAccount;
