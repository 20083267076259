import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

export default function TokenSupply() {
    const [totalTokensUsed, setTotalTokensUsed] = useState(0);
    const [maxTokens, setMaxTokens] = useState(0);

    useEffect(() => {
        fetch("https://presalebackend.cryptopoker.tech/total-tokens-used/")
            .then(response => response.json())
            .then(data => {
                setTotalTokensUsed(data.total_tokens_used || 0);
                setMaxTokens(data.max || 0);
            })
            .catch(error => {
                console.log("Error fetching total tokens used:", error);
            });
    }, []);

    const percentageUsed = (totalTokensUsed / maxTokens) * 100 || 0;

    return (
        <Box sx={{ marginBottom: "20px" }}>
            <h3 style={{ color: "#cecece" }}>Total tokens sold</h3>
            <Box sx={{ width: "100%", height: 40, borderRadius: "5px", background: "#16172c", border: "1px solid #282a4e", overflow: "hidden" }}>
                <Box sx={{ width: `${percentageUsed}%`, height: "100%", background: "linear-gradient(90deg, #555cd8 0.07%, #555cd8 99.9%)" }}></Box>
            </Box>

            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", marginTop: "3px" }}>
                <Typography sx={{ color: "darkgray" }}>{totalTokensUsed} tokens sold</Typography>
                <Typography sx={{ color: "darkgray" }}>{maxTokens} max tokens</Typography>
            </Box>
        </Box>
    );
}
