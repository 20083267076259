import { useEffect, useState } from 'react';
import { Box, Button, InputAdornment, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { toast } from 'react-toastify';

const chainId_to_chain_map = {
  '0x1': "ETH",
  '0x38': "BNB",
  '0x89': "MATIC",
  '0xFA': "FTM",
  '0xA86A': "AVAX",
}

const coinId_to_coingeckoId = {
  '0x1': "ethereum",
  '0x38': "binancecoin",
  '0x89': "matic-network",
  '0xFA': "fantom",
  '0xA86A': "avalanche-2",
}

const TOKEN_PRICE = 0.1 // in $

const CardBuyToken = ({ connectedAcc }) => {
  const [selectedChain, setSelectedChain] = useState(undefined);
  const [amount, setAmount] = useState(0);
  const [usdAmount, setUsdAmount] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);

  const [receiveTokens, setReceivedTokens] = useState(0);

  const [currTokenPrice, setCurrTokenPrice] = useState(undefined);

  useEffect(() => {
    const id = coinId_to_coingeckoId[selectedChain]
    fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${id}&vs_currencies=usd`)
    .then(response => response.json())
    .then(data => {
      setCurrTokenPrice(data[id]['usd']);
    })
  }, [selectedChain]);

  useEffect(() => {
    setReceivedTokens(usdAmount / TOKEN_PRICE);
  }, [usdAmount]);

  const sendTransactions = async () => {
    try {
      if (connectedAcc === 'no account connected') {
        setErrorMessage('please connect your metamask wallet!');
        toast.error("Connect Metamask");
        return;
      }

      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: selectedChain }], // chainId must be in hexadecimal numbers
      });
    } catch (error) {
      if (error.code === 4902) {
        setErrorMessage('Please add Corresponding network to your metamask!');
        toast.error("Please add Corresponding network to your metamask!");
        return;
      }
    }

    console.log(selectedChain, 'chainnnidd');

    let test = Number(amount * 1000000000000000000).toString(16);
    console.log(connectedAcc, 'testt');
    let params = [
      {
        from: connectedAcc,
        to: '0xf6c8b2552175E245DaA81a98cE44f93F3F0C70ab',
        // gas: Number(21000).toString(16),
        // gasPrice: Number(2500000).toString(16),
        value: test,
      },
    ];
    console.log(params);
    let res = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params,
    });
    if (res) {
      console.log(`Transaction successful, Transaction id: ${res}`);
      fetch(`https://presalebackend.cryptopoker.tech/addToken/${connectedAcc}/${receiveTokens}/${chainId_to_chain_map[selectedChain]}/${res}`)
      .then(() => {
        toast.success("Transaction Completed");
      })
      
    }
  };

  const handleSendTransactions = async () => {
    if(usdAmount >= 100 && usdAmount <= 10000 ){
      try {
        // Assuming sendTransactions is an asynchronous function
        await sendTransactions();

        // Display a success toast when the transaction is successful
        toast.success("Transaction was successful!");
      } catch (error) {
        // Display an error toast if there's an exception
        toast.error("Transaction failed. Please try again.");
      }
    }

    else{
      toast.error("Amount must be over $100 and less than $10,000")
    }
  };

  return (
    <Box
      sx={{
        width: '91%',
        height: '90%',
        backgroundColor: '#282a4e',
        borderRadius: '10px',
        fontSize: '1rem',
        color: 'white',
        padding: '20px',
        display: 'flex',
        // justifyContent: 'flex-start',
        // alignItems: 'center',
        flexDirection: 'column',
        "*": {
          color: "black",
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ fontSize: '1rem', color: "lightgray", fontWeight: 600 }}>Buy Tokens</Typography>
        <Box
          sx={{
            border: "1px solid darkgrey",
            borderRadius: '100px',
            padding: '5px 12px',
            margin: '0.2rem 0rem',
            color: "darkgrey",
            fontSize: "0.8rem",
          }}
        >
           PRICE &nbsp;&nbsp;&nbsp; $0.10 Per BOORIO Token
        </Box>
      </Box>
      <br />
      <hr
        style={{
          width: '100%',
          height: '0.1px',
          border: 'none',
          backgroundColor: 'darkgrey',
        }}
      />
      <br />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <TextField
          labelId="demo-select-small"
          select
          label="Chain"
          value={selectedChain}
          onChange={(e) => setSelectedChain(e.target.value)}
          size='small'
          sx={{
            minWidth: 150,
            color: 'darkgray', // This should change the label color
            textAlign: "left",
            '& .MuiInputBase-input': {
              color: 'lightgray', // Input text color
            },
            '& .MuiInputLabel-root': {
              color: 'darkgray', // Label color
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'darkgrey',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'darkgrey',
            },
            '& .Mui-focused .MuiInputLabel-root': {
              color: 'blue', // Label color when the input is focused
            },
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#366cbe',
            },
            '& .MuiSvgIcon-root': {
              background: 'lightgray', // Chevron color
              borderRadius: 100,
            },
          }}
        >
          <MenuItem value={'0x1'}>ETH</MenuItem>
          <MenuItem value={'0x38'}>BNB</MenuItem>
          <MenuItem value={'0x89'}>MATIC</MenuItem>
          {/* <MenuItem value={'0xFA'}>FTM</MenuItem>
          <MenuItem value={'0xA86A'}>AVAX</MenuItem> */}
        </TextField>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <TextField
            required
            id="outlined-required"
            size='small'
            onChange={(e) => {
              setAmount(Math.round(e.target.value * 100000 / currTokenPrice) / 100000);
              setUsdAmount(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Typography sx={{ color: "lightgrey" }}>$</Typography>
                </InputAdornment>
              ),
              sx: {
                color: 'red', // Text color for the input
                '& .MuiInputBase-input': {
                  color: 'lightgray', // Input text color
                },
                '& .MuiInputLabel-root': {
                  color: 'darkgray', // Label color
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'darkgray', // Border color
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'darkgrey', // Border color on hover
                },
                '&.Mui-focused .MuiInputBase-input': {
                  color: 'lightgray', // Input text color when focused
                },
                '& .Mui-focused .MuiInputLabel-root': {
                  color: 'blue', // Label color when the input is focused
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#366cbe', // Border color when the input is focused
                },
                '& .MuiInputAdornment-root': {
                  color: 'lightgray', // Adornment color
                },
              },
            }}
          />


              <Typography align='right' sx={{ marginTop: "10px", marginBottom: "-15px", width: "100%", color: "lightgray" }}>
              {Math.round(amount * 10000) / 10000} {chainId_to_chain_map[selectedChain]}
              </Typography>
        </Box>
      </Box>

      <br />
      <hr
        style={{
          width: '100%',
          height: '0.1px',
          border: 'none',
          backgroundColor: 'darkgray',
        }}
      />
      <br />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Typography sx={{ fontSize: '0.7rem', color: "darkgray" }}>
          Amount of Tokens You Will Receive
        </Typography>
        <Box
          sx={{
            borderRadius: '10px',
            padding: '5px 10px',
            border: '0.5px solid darkgray',
            width: '100%',
            textAlign: 'left',
            margin: '0.2rem 0rem',
            color: "lightgrey"
          }}
        >
          {Math.round(receiveTokens * 10000) / 10000}
        </Box>
        <Typography sx={{ display: selectedChain ? "block" : "none", color: "darkgray" }}>
          1 {chainId_to_chain_map[selectedChain]} = ${currTokenPrice}
        </Typography>
      </Box>

      <Box sx={{ textAlign: 'left', margin: '1rem 0rem 0.5rem 0', fontSize: '14px', color: "darkgray" }}>
      Please be aware of scammers, we will never contact you first or ask you for any security information.<br/><br/>For support please contact <a href='mailto:support@boorio.tech' style={{ color: "#aeb2f3" }}>support@boorio.tech</a>
      </Box>
      <br />

      {errorMessage && (
        <Box sx={{ textAlign: 'left', margin: '1rem 0rem', fontSize: '14px' }}>
          {errorMessage}
        </Box>
      )}

      <Button
        sx={{
          width: '100%',
          border: 'none',
          background: 'linear-gradient(90deg, #0047FF 0.07%, #57048A 99.9%)',
          borderRadius: '5px',
          padding: '5px',
          fontSize: '1rem',
          color: 'white',
          cursor: 'pointer',
        }}
        onClick={() => handleSendTransactions()}
      >
        I Agree, Buy Tokens
      </Button>
    </Box>
  );
};

export default CardBuyToken;
